// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-rdfsite-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-pages-index-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-pages-news-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/news.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-news-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-pages-partners-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/partners.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-partners-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-pages-publications-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/publications.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-publications-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-pages-team-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/pages/team.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-pages-team-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-templates-markdown-page-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/templates/markdownPage.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-templates-markdown-page-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-templates-news-page-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/templates/newsPage.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-templates-news-page-js" */),
  "component---node-modules-gatsby-theme-rdfsite-src-templates-person-page-js": () => import("./../../../node_modules/gatsby-theme-rdfsite/src/templates/personPage.js" /* webpackChunkName: "component---node-modules-gatsby-theme-rdfsite-src-templates-person-page-js" */)
}

